import React from 'react'

export default function Solutions() {
  return (
    <>
      <div className="row shadeSectionSolutions">
          <h2 className="poppins-semibold titleSolutions">
            POS Solutions Tailored for Local Businesses
          </h2>
          <p className="titleSolutionsPera">
            Maximize efficiency and compliance with our advanced digital POS
            systems. Enhance your workflow while ensuring 100% adherence to tax
            regulations. Boost sales by optimizing staff utilization and
            streamline accounting processes to save valuable time.
          </p>
          <div className="col-md-6 manageSection">
            <div className="manageImgIcon">
              <img
                src="assets/images/tickIcon.svg"
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="manageDescriptions">
              <h4 className="poppins-semibold">
                Manage Your Business Anywhere
              </h4>
              <p>
                Manage Your Business Anywhere With MY POS-IN, you have full
                control over your business anytime, anywhere. Our virtual office
                provides invaluable sales statistics and more, ensuring you stay
                connected and informed wherever you are.
              </p>
            </div>
          </div>

          <div className="col-md-6 manageSection">
            <div className="manageImgIcon">
              <img
                src="assets/images/tickIcon.svg"
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="manageDescriptions">
              <h4 className="poppins-semibold">Incredibly User-Friendly</h4>
              <p>
                Save time and effortlessly train new employees within minutes.
              </p>
            </div>
          </div>

          <div className="col-md-6 manageSection">
            <div className="manageImgIcon">
              <img
                src="assets/images/tickIcon.svg"
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="manageDescriptions">
              <h4 className="poppins-semibold">
                Exceptional Value for Your Investment
              </h4>
              <p>
                Unlock all the benefits and features of a professional POS
                system with unparalleled value for money.
              </p>
            </div>
          </div>

          <div className="col-md-6 manageSection">
            <div className="manageImgIcon">
              <img
                src="assets/images/tickIcon.svg"
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="manageDescriptions">
              <h4 className="poppins-semibold">Versatile for Every Business</h4>
              <p>
                The POS-IN PRO POS system is adaptable to suit the unique needs
                of your hospitality business. Alternatively, explore our compact
                cash register, POS-IN MINI, ideal for services, retail, and
                trade establishments alike.
              </p>
            </div>
          </div>
        </div>
    </>
  )
}

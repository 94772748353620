import React from "react";
import { Button } from "react-bootstrap";
import Solutions from "./component/solutions";

export default function PosInMini() {
  return (
    <>
      <div className="container-fluid main-container">
        <div className="row bookDemoNow detailSection">
          <div className="col-md-6">
            <h1 className="titleSection poppins-extrabold">
              <span className="spantitle">POS-IN MINI:</span>The Compact and
              Intelligent POS Solution
            </h1>
            <p className="titlePera">
              Looking for a POS system tailored to your needs? Introducing the
              POS-IN MINI, a mobile smart terminal meticulously crafted to
              support retailers, service providers, and creators during busy
              times.
            </p>
            <Button className="bookButton poppins-semibold">
              Receive a quote!
            </Button>
          </div>
          <div className="col-md-6 text-center mobileImgPos">
            <img
              src="assets/images/mobileposINe.svg "
              alt=" "
              className="img-fluid"
            />
          </div>
        </div>
      </div>

      <div className="container-fluid wirelessOrderSection">
        <div className="main-container">
          <div className="row">
            <div className="col-md-12 wirelessOrderCol">
              <h2 className="text-center poppins-semibold PosSoftwarePadding">
                User-Friendly POS Software
              </h2>
              <div className="text-center PosSoftwarePadding">
                <img
                  src="assets/images/mobileAllImages.svg "
                  alt=" "
                  className="img-fluid"
                />
              </div>
              <p className="text-center">
                Boasting over 15,000 satisfied customers, orderbird stands as
                the market leader for Cloud POS systems in Germany. Our latest
                innovation, orderbird MINI, caters specifically to retail,
                service providers, and creators. Thanks to its intuitive design,
                minimal training time is required, allowing you to start using
                it immediately.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="continer-fluid main-container">
        <div className="row varaitionAlign">
          <h4 className="poppins-semibold">
            The versatile mobile POS solution for all types of stores.
          </h4>
          <div
            className="col-md-3 varationsImages"
            style={{
              backgroundImage: `url('/assets/images/variation1.png')`,
            }}
          ></div>

          <div
            className="col-md-3 varationsImages"
            style={{
              backgroundImage: `url('/assets/images/variation2.png')`,
            }}
          ></div>

          <div
            className="col-md-3 varationsImages"
            style={{
              backgroundImage: `url('/assets/images/variation3.png')`,
            }}
          ></div>

          <div
            className="col-md-3 varationsImages"
            style={{
              backgroundImage: `url('/assets/images/variation4.png')`,
            }}
          ></div>

          <div
            className="col-md-3 varationsImages"
            style={{
              backgroundImage: `url('/assets/images/variation5.png')`,
            }}
          ></div>

          <div
            className="col-md-3 varationsImages"
            style={{
              backgroundImage: `url('/assets/images/variation6.png')`,
            }}
          ></div>
        </div>

        <Solutions />
      </div>
    </>
  );
}

import React from "react";
import { Button } from "react-bootstrap";
import Solutions from "./component/solutions";

export default function PosInPro() {
  return (
    <>
      <div className="container-fluid main-container">
        <div className="row bookDemoNow detailSection">
          <div className="col-md-6">
            <h1 className="titleSection poppins-extrabold">
              <span className="spantitle">POS-IN PRO:</span> Elevate Your
              Hospitality Business with Our POS System
            </h1>
            <p className="titlePera">
              Explore our comprehensive range of products and services tailored
              to meet your needs. Discover how orderbird can enhance your
              company's success, and request a no-obligation quote today!
            </p>
            <Button className="bookButton poppins-semibold">
              Receive a quote!
            </Button>
          </div>
          <div className="col-md-6 text-center">
            <img
              src="assets/images/Tab ImagePro.svg "
              alt=" "
              className="img-fluid"
            />
          </div>
        </div>
      </div>

      <div className="container-fluid wirelessOrderSection">
        <div className="main-container">
          <div className="row">
            <div className="col-md-12 wirelessOrderCol">
              <h2 className="text-center poppins-semibold">
                Boost Turnover with Wireless Ordering
              </h2>
              <div className="text-center">
                <img
                  src="assets/images/Img all section.svg "
                  alt=" "
                  className="img-fluid"
                />
              </div>
              <h6 className="poppins-medium">
                Achieve greater efficiency and success in your business with
                wireless ordering.
              </h6>
              <p>
                Embrace the flexibility of booking mobile devices during peak
                seasons, such as the summer months, with our iPad cash register
                system. The seamless wireless communication between iPod touch
                or iPhone and the stationary iPad cash register not only saves
                your waitstaff time but also reduces unnecessary walking
                distances. This means more time can be dedicated to attending to
                guests! Additionally, we provide support to your service staff
                with aisle control for enhanced menu planning and a customizable
                table plan for a perfect overview of your establishment.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid main-container">
        <div className="row businessCardSection">
          <div className="Cardheadersection">
            <h3 className="poppins-semibold">Discover How POS-IN Can Elevate Your Business Success</h3>
            <p className="poppins-medium">Select Your Business Type</p>
          </div>
          <div className="col-md-2 cardSection">
            <h6 className="poppins-semibold">Bar</h6>
            <img src="assets/images/bar.svg " alt=" " className="img-fluid" />
          </div>

          <div className="col-md-2 cardSection">
            <h6 className="poppins-semibold">Cafe</h6>
            <img src="assets/images/cafe.svg " alt=" " className="img-fluid" />
          </div>

          <div className="col-md-2 cardSection">
            <h6 className="poppins-semibold">Food Truck</h6>
            <img src="assets/images/truck.svg " alt=" " className="img-fluid" />
          </div>

          <div className="col-md-2 cardSection">
            <h6 className="poppins-semibold">Restaurant</h6>
            <img
              src="assets/images/restaurant.svg "
              alt=" "
              className="img-fluid"
            />
          </div>
        </div>


        <Solutions/>

      </div>
    </>
  );
}

import './App.css';
import Footer from './footer';
import Header from './header';
import RouteComponent from './routes';

function App() {
  return (
    <div>
      <Header/>
      <RouteComponent/>
      <Footer/>
    </div>
  );
}

export default App;

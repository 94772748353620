import React from "react";

export default function Footer() {
  return (
    <div className="footerSection">
      <div className="main-container container-fluid">
        <div className="row">
          <div className="col-md-4 footerlogo">
            <img src="assets/images/footerLogo.svg" alt=" " />
          </div>
          <div className="col-md-8 footerSocialAlign">
            <div className="socialLinks">
              <img src="assets/images/facebook.svg" alt=" " />
              <img src="assets/images/whatapp.svg" alt=" " />
              <img src="assets/images/linkedin.svg" alt=" " />
            </div>

            <div className="aboutTabs">
              <div>About us</div>
              <div>Partner</div>
              <div>Customer Referral Programm</div>
              <div>Careers</div>
            </div>
          </div>

          <hr />

          <div className="col-md-12 footerDescription">
            <h6>About POS-IN</h6>
            <p>
              POS-IN is a leading Point of Sale (POS) system provider in Europe,
              serving over 15,000 customers including restaurants, cafés, bars,
              clubs, and beer gardens. POS-IN has grown to become a trusted name
              in the hospitality industry. In addition to traditional POS
              systems, POS-IN also offers the innovative POS-IN MINI, a mobile
              cash register solution tailored for service providers, retailers,
              mobile hospitality, and crafts businesses.
            </p>
          </div>

          <hr />

          <div className="col-md-6 copyrightText">
            <p className="poppins-medium m-0">
              Copyright 2024. POS-IN. All Rights Reserved.
            </p>
          </div>
          <div className="col-md-6 policySection">
            <div>Privacy Policy</div>
            <div>Terms of Service</div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

export default function Header() {
  const location = useLocation();
  const [activePage, setActivePage] = useState('');

  useEffect(() => {
    setActivePage(location.pathname);
  }, [location.pathname]);

  return (
    <>
      <div className="section-header">
        <div className="main-container">
          <div className="row row-main-header">
            <div className="col-md-6 leftAlignCenter">
              <Link to="/">
                <img
                  className="header-logo"
                  src="assets/images/logo.svg"
                  alt=""
                />
              </Link>
              <div className="menu-ul">
                <Link to="/pos-in-pro">
                  <div className={`poppins-semibold ${activePage === '/pos-in-pro' ? 'activeMenu' : ''}`}>Pos-In PRO</div>
                </Link>
                <Link to="/pos-in-mini">
                  <div className={`poppins-semibold ${activePage === '/pos-in-mini' ? 'activeMenu' : ''}`}>Pos-In MINI</div>
                </Link>
                <Link to='/contact'>
                <div className={`poppins-semibold ${activePage === '/contact' ? 'activeMenu' : ''}`}>Contact</div>
                </Link>
              </div>
            </div>
            <div className="col-md-6">
              <div className="menu-ul-right">
                <button>
                  <img src="assets/images/icon-phone.svg" alt="" />
                  +95846852250
                </button>
                <button className="poppins-semibold button-request">
                  Request a Demo
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";
import { Button } from "react-bootstrap";

export default function Contact() {
  return (
    <>
      <div
        className="container-fluid contactColsection"
        style={{
          backgroundImage: `url('/assets/images/contactus.svg')`,
        }}
      >
        <div className="main-container">
          <div className="row bookDemoNow detailSection p-0">
            <div className="col-md-5">
              <h1 className="titleSection poppins-extrabold getinTouch">
                GET IN TOUCH
              </h1>
              <p className="titlePera">
                Want to get in touch ? we,d love to here from you. Here’s how
                you can reach us.....
              </p>
              <Button className="bookButton poppins-semibold contactBookDemo">
                Book a demo now
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid main-container ">
        <div className="row supportSectionCenter">
          <div className="col-md-5 contactCardSection">
            <img src="/assets/images/supportcontact.svg" />
            <h6 className="poppins-semibold m-0">Purchase advice</h6>
            <p className="m-0">
              We're here to provide personalized advice on our POS solutions
              through a face-to-face meeting. We eagerly await your call!
            </p>
            <p className="m-0">
              <span className="poppins-semibold">Hotline:</span> 010 106 882 999{" "}
              <br />
              <span className="poppins-semibold">
                Hotline international:
              </span>{" "}
              +49 80 105 584 990 <br />
              <span className="poppins-semibold">
                Or simply via E-Mail:
              </span>{" "}
              simple@posin.com
            </p>
            <Button className="bookButton poppins-semibold">
              Receive a quote!
            </Button>
          </div>

          <div className="col-md-5 contactCardSection">
            <img src="/assets/images/message.svg" />
            <h6 className="poppins-semibold m-0">Contact Customer Support</h6>
            <p className="m-0">
              Sometimes you need a little help from your friends. Or a hubSpot
              support rep. Don’t worry...
              <br /> we’re here for you.
            </p>
            <p className="m-0">
              <span className="poppins-semibold">Hotline:</span> 010 106 882 999{" "}
              <br />
              <span className="poppins-semibold">
                Hotline international:
              </span>{" "}
              +49 80 105 584 990 <br />
              <span className="poppins-semibold">
                Or simply via E-Mail:
              </span>{" "}
              simple@posin.com
            </p>
            <Button className="bookButton poppins-semibold">Contact us</Button>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row maincontactPriceSection">
          <div className="col-md-12 contactPriceHeader">
            <h2 className="m-0 poppins-semibold">Choose your pricing model</h2>
            <p className="m-0">
              Whether your business operates seasonally or year-round, our
              flexible pricing models ensure you'll find the perfect package for
              your company.
            </p>
          </div>

          <div className="col-md-12">
            <div className="row priceCenterSection">


              <div className="col-md-4 priceCardMob">
                <div className="priceCardSection">
                  <img src="/assets/images/pricemodal.svg" alt="" />
                  <h1 className="poppins-extrabold m-0">Starter</h1>
                  <p className="m-0">
                    In publishing and graphic design, Lorem ipsum{" "}
                  </p>
                  <div className="priceDetails">
                    <p className="m-0 price poppins-semibold">
                      <span>€</span>25
                    </p>
                    <span className="priceper">
                      per <br />
                      store
                    </span>
                  </div>
                  <div>
                    <Button className="bookButton poppins-semibold pricebtn">
                      Get Started
                    </Button>
                  </div>
                </div>
              </div>

              <div className="col-md-4 priceCardMob">
                <div className="priceCardSection">
                  <img src="/assets/images/pricemodal.svg" alt="" />
                  <h1 className="poppins-extrabold m-0">PRO</h1>
                  <p className="m-0">
                    Lorem ipsum may be used as a placeholder before the final
                    copy is available In publishing and graphic design, Lorem
                    ipsum 
                  </p>
                  <div className="priceDetails">
                    <p className="m-0 price poppins-semibold">
                      <span>€</span>125
                    </p>
                    <span className="priceper">
                      per <br />
                      store
                    </span>
                  </div>
                  <div>
                    <Button className="bookButton poppins-semibold pricebtn">
                      Choose pro
                    </Button>
                  </div>
                </div>
              </div>

              <div className="col-md-4 priceCardMob">
                <div className="priceCardSection">
                  <img src="/assets/images/pricemodal.svg" alt="" />
                  <h1 className="poppins-extrabold m-0">Business</h1>
                  <p className="m-0">
                    In publishing and graphic design, Lorem ipsum{" "}
                  </p>
                  <div className="priceDetails">
                    <p className="m-0 price poppins-semibold">
                      <span>€</span>450
                    </p>
                    <span className="priceper">
                      per <br />
                      store
                    </span>
                  </div>
                  <div>
                    <Button className="bookButton poppins-semibold pricebtn">
                      choose Business
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

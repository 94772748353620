import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './home'
import PosInPro from './posInPro'
import PosInMini from './posInMini'
import Contact from './contact'

export default function RouteComponent() {
  return (
    <Routes basename={'app'}>
        <Route  path={'/'} element={<Home />} />
        <Route  path={'/pos-in-pro'} element={<PosInPro />} />
        <Route  path={'/pos-in-mini'} element={<PosInMini />} />
        <Route  path={'/contact'} element={<Contact />} />
    </Routes>
  )
}
import React from "react";
import { Button } from "react-bootstrap";
import Solutions from "./component/solutions";

export default function Home() {
  return (
    <>
      <div className="container-fluid main-container">
        <div className="row bookDemoNow detailSection">
          <div className="col-md-6">
            <h1 className="titleSection poppins-extrabold">
              Advanced POS solutions tailored for your local business
            </h1>
            <p className="titlePera">
              Cutting-edge POS systems featuring certified TSE for caterers,
              retailers, and service providers. Streamlined operation, seamless
              card payment integration, and complete adherence to tax office
              requirements.
            </p>
            <Button className="bookButton poppins-semibold">
              Book a demo now
            </Button>
          </div>
          <div className="col-md-6 text-center">
            <img
              src="assets/images/Tab-View.svg "
              alt=" "
              className="img-fluid"
            />
          </div>
        </div>

        <div className="row">
          <p className="ourSolutionTitle poppins-semibold">Our POS solutions</p>

          <div className="col-md-6 outerPadding">
            <div className="shadeSection">
              <div className="headerImg">
                <img
                  src="assets/images/Tab-View1.svg "
                  alt=" "
                  className="img-fluid"
                />
              </div>
              <div className="descriptionSection">
                <h3 className="poppins-semibold">
                  Introducing POS-IN PRO for the hospitality industry
                </h3>
                <h6 className="poppins-semibold">
                  Simplify your cash register operations effortlessly
                </h6>
                <p>
                  Maximize the efficiency of your hospitality business with
                  POS-IN PRO, a digital POS system designed to combat staffing
                  shortages. Save time and reduce unnecessary foot traffic with
                  wireless ordering, empowering you to allocate your staff more
                  effectively.
                </p>
                <div className="btnSection">
                  <Button className="bookButton poppins-semibold">
                    Learn more
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 outerPadding">
            <div className="shadeSection">
              <div className="headerImg">
                <img
                  src="assets/images/MobileView.svg "
                  alt=" "
                  className="img-fluid"
                />
              </div>
              <div className="descriptionSection">
                <h3 className="poppins-semibold">
                  Introducing POS-IN MINI - A Compact and Versatile POS System
                </h3>
                <h6 className="poppins-semibold">
                  Efficient Management Made Easy
                </h6>
                <p>
                  Experience the convenience of a compact, all-in-one cash
                  register tailor-made for resorts.
                </p>
                <div className="btnSection">
                  <Button className="bookButton poppins-semibold">
                    Learn more
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <p className="ourSolutionTitle poppins-semibold">
            What customers say about POS-IN
          </p>

          <div className="col-md-4 mainCustomerSection">
            <div className="customerShadeSection">
              <div
                style={{
                  backgroundImage: `url('/assets/images/customer1.png')`,
                }}
                className="backgroundImage"
              ></div>
              <p className="m-0">
                "As a retail store manager, I've tried several POS systems, but
                none have come close to the efficiency and reliability of
                POS-IN. The system's intuitive design makes staff training a
                breeze, and the real-time sales data helps us make informed
                business decisions. Plus, the responsive customer support team
                is always there to assist whenever we need help. POS-In has
                definitely exceeded our expectations."
              </p>
              <h6 className="poppins-semibold m-0">
                Markus L. - Retail Store Manager
              </h6>
            </div>
          </div>

          <div className="col-md-4 mainCustomerSection">
            <div className="customerShadeSection">
              <div
                style={{
                  backgroundImage: `url('/assets/images/customer2.png')`,
                }}
                className="backgroundImage"
              ></div>
              <p className="m-0">
                "Switching to POS-In for our cafe was one of the best decisions
                we've made. The system's versatility allows us to customize
                orders easily, whether it's for a simple espresso or a complex
                specialty drink. The built-in reporting features have been
                invaluable for tracking sales trends and identifying our
                best-selling items. We couldn't be happier with POS-In!"
              </p>
              <h6 className="poppins-semibold m-0">Anna S. - Cafe Owner</h6>
            </div>
          </div>

          <div className="col-md-4 mainCustomerSection">
            <div className="customerShadeSection">
              <div
                style={{
                  backgroundImage: `url('/assets/images/customer3.png')`,
                }}
                className="backgroundImage"
              ></div>
              <p className="m-0">
                "POS-In has been a game-changer for our small business. Its
                affordability and scalability make it the perfect solution for
                businesses of all sizes.The team at POS-In has been incredibly
                supportive throughout the setup process, ensuring a smooth
                transition for our staff. I highly recommend POS-In to any small
                business looking for a reliable POS solution."
              </p>
              <h6 className="poppins-semibold m-0">
                Michael T. - Small Business Owner
              </h6>
            </div>
          </div>
        </div>

        <Solutions/>
      </div>
    </>
  );
}
